<template>
    <aside :class="{landscape: mq.isLandscape, mobile: mq.mdMinus && !mq.isLandscape, tablet: (!mq.mdMinus || mq.isLandscape) && !mq.xlPlus, desktop: mq.xlPlus}">
        <div class="layout-header"></div>
        <header>
            <img
                :src="IntegeriLogo"
                alt="Integeri"
                class="logo"
            />
            <div v-if="mq.xlMinus"
                 class="version-tag">
                {{ trans('login.version') }} {{ version }}
            </div>
        </header>

        <section class="image">
            <img
                :src="LoginImage"
                :alt="'Integeri ' + trans('login.vision')"
            />
        </section>

        <slot />

        <footer :class="{landscape: mq.isLandscape}">
            <div v-if="!mq.xlMinus"
                 class="version-tag">
                {{ trans('login.version') }} {{ version }}
            </div>
            <nav>
                <a href="https://www.integeri.com/kontakt" target="integerihome">{{ trans('login.menu.contact') }}</a>
                <a href="https://www.integeri.com/faq" target="integerihome">{{ trans('login.menu.faq') }}</a>
                <a href="https://www.integeri.com/agb" target="integerihome">{{ trans('login.menu.tac') }}</a>
                <a href="https://www.integeri.com/datenschutz" target="integerihome">{{ trans('login.menu.privacy') }}</a>
                <a href="https://www.integeri.com/impressum" target="integerihome">{{ trans('login.menu.legalnote') }}</a>
            </nav>
        </footer>
        <div class="layout-footer"></div>
    </aside>
</template>

<script setup>
import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3';
import IntegeriLogo from '@images/logo.svg';
import LoginImage from '@images/login-image.png';
import { useMq } from 'vue3-mq';
const mq = useMq();

const version = computed(() => usePage().props.app.version);
</script>

<style lang="scss" scoped>
aside {
    background: #F4F7FC;
    padding: 50px;
    display: grid;
    grid-template-rows: auto auto auto 30px 2fr auto;
    position: relative;

    a {
        white-space: nowrap;
    }

    & .version-tag {
        font-size: 12px;
        opacity: 0.4;
        white-space: nowrap;
    }

    & .layout-header {
        background: url('@/../images/layout-header.png') no-repeat top right;
        background-size: contain;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        aspect-ratio: 7.5;
    }

    & .layout-footer {
        background: url('@/../images/layout-footer.png') no-repeat bottom left;
        background-size: contain;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        aspect-ratio: 7.5;
        pointer-events: none;
    }

    & header {
        display: flex;
        gap: 15px;
        align-items: baseline;

        & .logo {
            height: 35px;
        }
    }

    & .image {
        display: flex;
        align-items: center;
        justify-content: center;

        & img {
            max-width: 70%;
            max-height: 70%;
        }
    }
    & footer {
        display: grid;
        grid-template-columns: 1fr auto;
        margin-bottom: 20px;

        & nav {
            display: flex;
            gap: 10px;

            & a {
                background: var(--app-color-blue-background);
                color: var(--app-color-blue);
                padding: 5px 10px;
                text-decoration: none;
                border-radius: 4px;
                font-size: 12px;
                font-weight: bold;
                transition: 0.15s ease-in-out all;

                &:hover {
                    background: var(--app-color-blue);
                    color: var(--app-color-white);
                }
            }
        }
    }
}
aside.mobile {
    padding: 5px;
    width: 100vw;
    max-height: 100vh;
    
    & header {
        max-width: calc(100vw - 10px);
    }

    & nav {
        gap: 4px;
        width: calc(100vw - 5px);
    }
    
    & .layout-footer {
        margin: 0px;
    }
}
aside.tablet {
    padding: 20px;
    max-height: unset;
    grid-template-rows: auto 2fr;
    &.landscape {
        max-width: 50vw;
        & .image {
            max-width: calc(50vw - 40px);
        }
    }
    & footer.landscape {
        max-width: calc(50vw - 40px);
        & nav {
            gap: 5px;
            & a {
                padding: 5px;
                font-size: 10px;
            }
        }
    }
}
aside.desktop {
    max-height: unset;
    grid-template-rows: auto 2fr;
    & .image {
        max-height: unset;
    }
}
</style>
